<template>
  <div>
    <modal
      name="linkEdit"
      height="auto"
      width="900"
      :scrollable="true"
      :clickToClose="false"
    >
      <CloseModal :close="close" />
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="`Salvando ${descSingular}`">
              <div class="xrow">
                <div class="col-1-4">
                  <div class="form-group">
                    <label for="">Prefixo</label>
                    <input class="form-control" v-model="item.prefixo" />
                  </div>
                </div>
                <div class="col-3-4">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <input class="form-control" v-model="item.descricao" />
                    <div class="message">
                      {{ validation.firstError("item.descricao") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Nova Aba</label>
                    <select class="form-control" v-model="item.nova_aba">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">
                      {{ validation.firstError("item.nova_aba") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Ordem</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="item.ordem"
                    />
                    <div class="message">
                      {{ validation.firstError("item.ordem") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Tipo de Link</label>
                    <select v-model="item.tipo" class="form-control">
                      <option value="Pagina">Página</option>
                      <option value="Link Externo">Link Externo</option>
                    </select>
                  </div>
                </div>
                <div class="col-1-2" v-if="!isLinkExterno">
                  <div class="form-group">
                    <label for="">{{ item.tipo || "-" }}</label>
                    <select class="form-control" v-model="item.link">
                      <option
                        v-for="pagina in paginas"
                        v-bind:key="pagina.id"
                        :value="pagina.slug"
                      >
                        {{ pagina.titulo }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-1-2" v-if="isLinkExterno">
                  <div class="form-group">
                    <label for>Link</label>
                    <input
                      type="text"
                      v-model="item.link"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Ativo</label>
                    <select class="form-control" v-model="item.ativo">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">
                      {{ validation.firstError("item.ativo") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-3" v-if="!!secoes">
                  <div v-if="this.item.local == 'rodape'">
                    <div class="form-group">
                      <label for="">Seção Rodapé</label>
                      <select
                        class="form-control"
                        v-model="item.secao_rodape_id"
                      >
                        <option value>-</option>
                        <option
                          v-for="secao in secoes"
                          v-bind:key="secao.id"
                          :value="secao.id"
                        >
                          {{ secao.descricao }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-1-1 add-image">
                  <label>
                    Adicionar Imagem
                    <image-picker
                      label="Imagem "
                      placeholder="Colar URL ou clique ao lado para upload"
                      v-model="item.imagem"
                    />
                  </label>
                </div>
              </div>

              <div class="xrow edit-buttons">
                <button
                  :class="{
                    'button button-success': true,
                    'button-disabled': validation.hasError(),
                  }"
                  v-on:click="handleSubmit"
                >
                  Salvar
                </button>
                <button class="button" v-on:click="close">Fechar</button>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Validator } from "simple-vue-validator";
import CloseModal from "../../../components/CloseModal";
import ImagePicker from "../../../components/ImagePicker";

export default {
  name: "linkEdit",
  props: [
    "item",
    "update",
    "close",
    "modulos",
    "descSingular",
    "paginas",
    "secoes",
  ],
  components: {
    CloseModal,
    ImagePicker,
  },
  computed: {
    isLinkExterno() {
      return this.item && this.item.tipo === "Link Externo";
    },
  },
  mounted() {},
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
    "item.nova_aba": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
  },
};
</script>
