<template>
    <div>
        <div class="filters row" style="margin-top:0">
            <div class="col-1-1">
                <div class="pull-right">
                    <button v-on:click="openCreate" class="button button-success button-block">
                        <fa-icon icon="plus"/>
                        Criar
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Imagem</th>
                    <th>Link</th>
                    <th>Seção</th>
                    <th>Título</th>
                    <th>Ordem</th>
                    <th>Nova Aba</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items">
                    <td>
                        <img class="img-thumbnail" :src="item.imagem"/>
                    </td>
                    <td>
                        <a target="_blank" :href="item.link" v-if="item.link">
                            <CButton class="btn btn-sm btn-link">
                                <CIcon name="cil-file"/>
                                Prévia
                            </CButton>
                        </a>
                    </td>
                    <td>
                          <span class="label warning" v-if="retornaNomeSecao(item.secao_rodape_id)">
                            {{ retornaNomeSecao(item.secao_rodape_id) }}
                          </span>
                    </td>
                    <td>{{ item.titulo }}</td>
                    <td>
                        <span class="label primary">{{ item.ordem }}</span>
                    </td>
                    <td>
                        <span class="label success" v-if="item.nova_aba">Sim</span>
                        <span class="label error" v-else>Não</span>
                    </td>
                    <td class="actions">
                        <button type="button" class="button button-primary" v-on:click="openEdit(item)">
                            <fa-icon icon="pencil-alt"/>
                        </button>
                        <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error">
                            <fa-icon icon="trash-alt"/>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'list',
    props: ['items', 'openEdit', 'openCreate', 'deleteConfirm', 'secoes'],
    data() {
      return {}
    },
    computed: {},
    mounted() {
    },
    methods: {
      retornaNomeSecao(secaoId) {
        if (this.secoes) {
          var resultado = this.secoes.find(secao => secao.id == secaoId);
          return resultado?.descricao;
        }
      }
    }
  }
</script>